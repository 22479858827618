<template>
  <div>
    <CRow>
      <CCol lg="12">
        <CCard>
          <CCardHeader>
            <strong>{{$t('emailNewsletter.title')}}</strong>
          </CCardHeader>
          <CCardBody>
            <h4 class="sub-title">{{$t('emailNewsletter.subscription')}}</h4>
            <CForm class="my-3">
              <CRow>
                <CCol>
                  <transition name="fade">
                    <CListGroup v-if="loadData">
                      <CListGroupItem
                        v-for="option in emailOptions"
                        :key="option"
                      >
                        <CSwitch
                          size="sm"
                          color="info"
                          :checked.sync="option.subscribed"
                          variant="opposite"
                          labelOn="on"
                          labelOff="off"
                        />
                        <strong :class="
                            option.subscribed ? 'text-dark' : 'text-secondary'
                          ">
                          {{ option.categoryName }}</strong>
                      </CListGroupItem>
                    </CListGroup>
                  </transition>
                </CCol>
              </CRow>
            </CForm>
          </CCardBody>
          <CCardFooter>
            <CButton
              color="success"
              class="ml-0 px-5"
              @click="saveOption()"
            >
              <i class="icon cil-task"></i> {{$t('common.update')}}

            </CButton>
          </CCardFooter>
        </CCard>
      </CCol>
    </CRow>
    <!-- start_completeModal -->
    <CModal
      color="success"
      :closeOnBackdrop="false"
      :show.sync="successModal"
    >
      <div class="coupon-info">{{ $t("commonMessage.updateSuccessText") }}
      </div>
      <template #header> {{ $t("commonMessage.updateSuccessHeader") }}
      </template>
      <template #footer>
        <CButton
          @click="successModal = false"
          color="secondary"
        >
          <i class="icon cil-x"></i> {{$t('common.close')}}

        </CButton>
      </template>
    </CModal>
    <!-- start error modal  -->
    <CModal
      color="danger"
      closeOnBackdrop
      :centered="true"
      :show.sync="errorModal"
    >
      <p>{{ errorMessage }}</p>
      <template #header>
        <h4 class="modal-title">{{$t('common.error')}}
        </h4>
      </template>
      <template #footer>
        <CButton
          @click="errorModal = false"
          color="secondary"
        >
          <i class="icon cil-x"></i> {{$t('common.close')}}

        </CButton>
      </template>
    </CModal>
  </div>
</template>

<script>
import axios from "axios"
import config from "../../../config"

//会員システムのurl
const LIBERTY_MEMBER_SERVER_URL = config.LIBERTY_MEMBER_SERVER_URL;

export default {
  name: "MailMagazineList",
  data() {
    return {
      selectedOption: "",
      emailOptions: [
        {
          userid: "",
          categoryId: 100,
          categoryName: "",
          subscribed: true,
        },
      ],
      successModal: false,
      errorModal: false,
      errorMessage: "",
      loadData: false,
    };
  },

  beforeMount() {
    this.getOptionList();
    console.log(this.$i18n.t(this.$route.name));
  },

  methods: {
    getOptionList: function () {
      const username = this.$keycloak.userName;
      var urlRequest = `${LIBERTY_MEMBER_SERVER_URL}/rest/users/${username}/email/preference`;
      axios
        .get(urlRequest, {
          headers: {
            Authorization: `Bearer ${this.$keycloak.token}`,
          },
        })
        .then((resp) => {
          console.log("all options:" + resp.data);
          this.emailOptions = resp.data;
          this.loadData = true;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    saveOption: function () {
      var updateOptions = this.emailOptions;
      var selectedOption = [];
      updateOptions.forEach((element) => {
        selectedOption.push({
          categoryId: element.categoryId,
          subscribed: element.subscribed,
        });
      });
      var updateOption = {
        userid: updateOptions[0].userid,
        preferences: selectedOption,
      };
      console.log(JSON.stringify(updateOption));

      // var urlRequest =
      //   "https://points.liberty-resort.net/rest/users/" +
      //   this.$keycloak.userName +
      //   "/email/preference";
      const username = this.$keycloak.userName;
      var urlRequest = `${LIBERTY_MEMBER_SERVER_URL}/rest/users/${username}/email/preference`;

      axios
        .put(urlRequest, updateOption, {
          headers: { Authorization: `Bearer ${this.$keycloak.token}` },
        })
        .then((resp) => {
          console.log(resp);
          this.successModal = true;
          this.getOptionList();
        })
        .catch((err) => {
          console.log(err);
          this.errorModal = true;
          this.errorMessage = err;
        });
    },
  },
};
</script>
<style lang="scss">
.list-group-item {
  label,
  strong {
    float: left;
  }
  label {
    margin-right: 10px;
  }
}
</style>
  